/*@charset "utf-8";
/*====================================== 

 [ -Main Stylesheet-
  Theme: Lambert
 ]
 
*/
/*-------------Import fonts ---------------------------------------*/


/*@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,600,700,900|Cabin:400,700|Droid+Serif:400,700|Playball);*/
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: "Barlow", sans-serif; */

/*-------------General Style---------------------------------------*/
html{
	overflow-x:hidden !important;
	height:100%;
}
body{
	margin: 0;
	padding: 0;
	/*font-family: 'Source Sans Pro', sans-serif;*/
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: #000;
	background-color:#191919;
	height:100%;
	text-align:center;
}
@-o-viewport {width: device-width;}
@-ms-viewport {width: device-width;}
@viewport {width: device-width;}
/*--------------Typography--------------------------------------*/
h1 {
	font-size: 4.46666666667em;
	text-transform: uppercase;
	font-weight: 400;
	line-height: 1.17em;
}
h2 {
	font-size: 3.2em;
	font-weight: 300;
}
h3 {
	font-size: 1em;
}
h4 {
	font-size: 0.9em;
	font-weight: 200;
}
h5 {
	font-size: 0.8em;
	font-weight: 200;
}
h6 {
	font-size: 0.6em;
	font-weight: 200;
}
p {
	text-align:left;
	font-size:19px;
	line-height:1.3;
	padding-bottom:10px;
	color:#333
}
blockquote {
	float:left;
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 15px solid #eee;
}
blockquote p {
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	color:#494949;
}
/* ---------Page preload--------------------------------------*/
.loader {
	position:fixed;
	top:50% !important;
	left:50%;
	width:max-content;
	height:max-content;
	margin-left:-25px;
	z-index:100;
	font-size:12px;
	line-height:50px;
	color:#fff;
	transform: translate(-27%, -50%);
}
.loader img{
	animation:k3d 3.5s infinite linear
}
@keyframes k3d {
0% {
  transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(360deg);
	opacity:0.3;
  }
  100%{
     transform: rotateY(0deg);
  }

}
/* ---------Content Styles--------------------------------------*/
#main{
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:2;
	opacity:0;
}
#wrapper{
	float:left;
	position: relative;
	z-index:2;
	width:100%;
	background:#fff;
}
.container{
	max-width: 1324px;
	width:92%;
	margin:0 auto;
	position: relative;
	z-index:2;
}
.content {
	width:100%;
	position:relative;
	z-index:2;
	float:left;
}
.full-height {
	height:100%;
}
.bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-size: cover;
	background-attachment: scroll  ;
	background-position: center  ;
	background-repeat:repeat;
	background-origin: content-box;
	overflow:hidden;
}
.respimg {
	width:100%;
	height:auto;
}
.overlay {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:#000;
	opacity:0.2;
	z-index:2;
}
/* --------- Section's--------------------------------------*/
section {
	float:left;
	width:100%;
	position:relative;
	padding:90px 0;
	background:#fff;
}
.parallax-section {
	padding:150px 0;
	color:#fff;
	overflow:hidden;
}
.parallax-section .overlay {
	opacity:0.5;
}
.section-separator {
	float:right;
	width:70%;
	height:6px;
	background:#000;
	margin-right:-20%;
}
.bg-parallax {
	height:150%;
	top:-25%;
}
.parallax-section h2 {
	text-transform:uppercase;
	font-weight:bold;
	font-size:4.5em;
	letter-spacing:6px;
	padding-bottom:20px;
	font-family: 'Neuropolitical Rg';
}
.parallax-section h3{
	color:#fff;
	font-size:34px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
section.header-section {
	padding:250px 0;
	border-top:20px solid #fff;
}
.section-title {
	float:left;
	width:100%;
	margin-bottom:20px;
}
.section-title h3 {
	font-size:52px;
	/*/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.section-title h4 {
	font-size:19px;/*1.8em;*/
	text-transform:uppercase;
	font-weight:bold;
	letter-spacing:1px;
	position:relative;
	display:inline-table;
	padding-bottom:20px;
	font-family: 'Neuropolitical Rg';
	padding-inline: 36px;
	text-wrap:balance;
}
.decor-title:before ,  .decor-title:after {
	content:'';
	position:absolute;
	top:12px;
	background:url(../images/bef-af.png) no-repeat center;
	width:26px;
	height:6px;
}
.decor-title:before {
	left:0;
}
.section-title h4:after {
	right:0;
}
.triangle-decor {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  z-index: 57;
}
.about-section{
	padding-bottom:20px;
}
.about-section .link {
	margin-top:20px;
	text-align: center;
}
.about-section .single-slider-holder {
	margin-top:80px;
}
.bold-separator {
	float:left;
	width:100%;
	margin:50px 0;
	position:relative;
}
.bold-separator span {
	width:6px;
	height:6px;
	display:inline-table;
	background:#191919;
	margin:0 auto;
	border-radius:100%;
}
.bold-separator:before , .bold-separator:after {
	content:'';
	position:absolute;
	width:10%;
	height:1px;
	top:2px;
	opacity:0.3;
}
.bold-separator:before {
	left:30%;
}
.bold-separator:after {
	right:30%;
}
.parallax-section .bold-separator span {
	background:#fff;
}
.align-text {
	text-align:left;
}
.img-wrap {
	position:absolute;
	top:0;
	right:0;
	width:30%;
	overflow:hidden;
	height:100%;
	z-index:4;
}
.no-border {
	border:none !important;
}
.no-padding {
	padding:0 !important;
}
.inner {
	max-width:800px;
	margin:0 auto;
}
.inner  p {
	text-align:center;
}
.link {
	font-size:15px;
	/*padding:10px 15px;*/
	padding: 15px 40px;
	display:inline-table;
	font-weight:bold;
	background: #000;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
	margin-block:20px;
    transition: all 200ms linear;
	border-radius: 4px;
	text-transform:uppercase;
}
.align-link {
	margin-top:30px;
}
.link:hover {
	color:#fff;
	background: #C59D5F;
}
.align-link:hover {
	color:#fff;
}
.section-icon {
	width:60px;
	height:60px;
	line-height:60px;
	margin:10px auto;
	background:rgba(255,255,255,0.21);
	border:2px solid rgba(255,255,255,0.51);
	border-radius:100%;
	font-size:20px;
}
/*------ Header  ------------------------------------------------*/
header{
	position:fixed;
	top:0;
	left:0;
	z-index:20;
	width:100%;
	-webkit-transform: translate3d(0,0,0);
}
.header-inner {
	float:left;
	width:100%;
	position:relative;
	padding:30px 0;
	top:0;
    transition: all 300ms linear;
}
.header-inner:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:0;
	background:#fff;
    transition: all 200ms linear;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.logo-holder {
	float:left;
	position:relative;
	max-width:200px;
	margin-top: 20px;
	@media only screen and  (max-width: 1036px) {
		margin-top: 0px;
	}
}
.logo-holder a {
	margin-top:0px;
	display:block;
	position:relative;
}
.logo-vis {
	position:relative;
	z-index:1;
    transition: all 300ms linear;
	width:200px;
}
.logo-notvis {
	position:absolute;
	top:0;
	left:0;
	z-index:2;
    transition: all 300ms linear;
	opacity:0;
}
.logo-sticky  img.logo-vis {
	/*opacity:0;*/
}
.logo-sticky img.logo-notvis {
	opacity:1;
}
header.sticky .header-inner:before , header.flat-header .header-inner:before {
	height:100%;
}
header.sticky .header-inner {
	/*top:-50px;*/
	padding:25px 0;
}
/*------ Navigation  ------------------------------------------------*/
.nav-holder {
	float:right;
	position:relative;
	margin-top:20px;
}
nav {
	position:relative;
	float:left;
	width:100%;
}
nav li{
	float:left;
	position:relative;
	padding: 10px 22px;
}
nav li a {
	float: left;
	line-height: 1;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	top:0;
	position:relative;
	font-weight:500;
	font-family: "Barlow", sans-serif;
    transition: all 100ms linear;
}
nav li a:before , nav li a:after {
	content:'';
	position:absolute;
	top:50%;
	margin-top:-2px;
	width:4px;
	height:4px;
	background:#fff;
	border-radius:100%;
    transition: all 300ms linear;
	transform: scale(0.1);
	opacity:0;
}
nav li a:before {
	left:-16px;
}
nav li a:after {
	right:-16px;
}
nav li.act-link a:before , nav li.act-link a:after {
	transform: scale(1.0);
	opacity:1;
}
nav li ul a:before , nav li ul a:after {
	display:none;
}
nav li ul a.act-link {
	color:#000;
	font-weight:600;
}
nav li ul {
	margin: 30px 0 0 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	min-width:150px;
	top: 38px;
	left: 0;
	z-index: 1;    	
	transition: all .2s ease-in-out;
	border:1px solid rgba(255,255,255,0.41);
	background:rgba(255,255,255,0.21);
}
nav li:hover > ul {
	opacity: 1;
	visibility: visible;
	margin: 0;
}
nav li:last-child {
	padding-right:0;
}
.nav-button-holder {
	top:44px;
	right:20px;
	display:none;
	position:absolute;
	@media only screen and  (max-width: 1036px) {
		top:0px;
	}
}
.nav-button {
	float:right;
	width:28px;
	height:24px;
	position:relative;
	margin-top:22px;
	cursor:pointer;	
	@media only screen and  (max-width: 1036px) {
		margin-top:7px;
	}
}
.nav-button span {
	float:left;
	width:100%;
	height:2px;
	background:#fff;
	margin-bottom:4px;
	position:relative;
}
header.sticky nav a ,  header.flat-header nav a{
	color:#000;
}
header.sticky nav li a:before  , header.sticky nav li a:after , header.flat-header nav li a:before , header.flat-header    nav li a:after{
	background:#000;
}
header.sticky .nav-button span  , header.flat-header .nav-button span{
	background:#000;
}
header.sticky nav li ul {
	background:rgba(255,255,255,1);
}
.nav-social {
	float:right;
	width:100%;
	position:relative;
    transition: all 600ms linear;
	top:10px;
}
.nav-social ul {
	float:right;
}
.nav-social li  {
	float:left;
	padding:10px;
}
.nav-social li:last-child {
	padding-right:0;
}
.nav-social li a {
	color:#fff;
	font-size:14px;
}
header.sticky   .nav-social   {
	top:-110px;
}
.subnav {
	float:right;
	margin:30px 6px 0 0 ;
}
.subnav a {
	display:inline-block;
	line-height: 1;
	font-size: 13px;
	color: #fff;
	top:0;
	padding-left:16px;
	position:relative;
    transition: all 100ms linear;
}
.subnav a i {
	padding-right:4px;
}
header.sticky nav a , header.sticky .subnav a  , header.flat-header .nav-social li a , header.flat-header .subnav a {
	color:#000;
}
.height-emulator {
	float:left;
	width:100%;
	position:relative;
	z-index:1
}
/*------ Home -------------------------------------------------*/
.hero-link {
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:20px;
	background:#fff;
	z-index:15;
}
.hero-link a {
	font-size:24px;
	position:absolute;
	width:50px;
	height:50px;
	left:50%;
	margin-left:-25px;
	margin-top:-20px;
    transition: all 200ms linear;
}
.hero-line {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:20px;
	background:#fff;
	z-index:20;
}
.hero-content:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:20px;
	height:100%;
	background:#fff;
	z-index:20;
}
.hero-content:after {
	content:'';
	position:absolute;
	top:0;
	right:0;
	width:20px;
	height:100%;
	background:#fff;
	z-index:20;
}
.hero-link a:before {
	content:'';
	position:absolute;
	top:-10px;
	left:50%;
	margin-left:-40px;
	width:80px;
	height:80px;
	border-radius:100%;
	background:#fff;
	z-index:-1;
}
.fullheight-carousel-holder {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.fullheight-carousel {
	 height:100%;
	 background:#191919;
	 width:100%;
	 overflow:hidden;
}
.fullheight-carousel .item , .carousel-item{
	 width:100%;
	 height:100%;
	 float:left;
	 overflow:hidden;
	 position:relative;
	display:block; 
}
.carousel-item  .overlay  {
	opacity:0.5;
    transition: all 300ms linear;
}
.carousel-link-holder {
	width:100%;
	position:absolute;
	top:50%;
	left:0;
	z-index:5;
}
.carousel-item .bg {
    transition: all 500ms linear;
}
.vis-decor .bg {
	transform: scale(1.2);
}
.vis-decor .overlay {
	opacity:0.7;
}
.carousel-link-holder  h3 {
	font-size:40px;
	position:relative;
	width:100%;
	color:#fff;
	font-weight:bold;
	letter-spacing:3px;
	padding:10px 0;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.carousel-link-holder  h3 a{
	color:#fff;
}
.carousel-link-holder  h4 {
	font-size: 14px;
	text-transform:uppercase;
	letter-spacing:2px;
	position:relative;
	color:#fff;
	font-weight:bold;
	max-width:350px;
	margin:0 auto;
}
.media-container {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
}
.fullheight-carousel-holder .customNavigation {
	position:absolute;
	bottom:40px;
	right:60px;
	z-index:5;
}
.fullheight-carousel-holder .customNavigation a {
	width:40px;
	height:40px;
	margin-right:1px;
	float:left;
	line-height:40px;
	color:#fff;
	font-size:17px;
	cursor:pointer;
    transition: all 500ms linear;
}
.fullheight-carousel-holder .customNavigation a:hover {
	transform: scale(0.9);
}
.hero-title-holder {
	position:absolute;
	top:0;
	width:100%;
	height:100%;
	z-index:3;
}
.hero-title-holder .overlay {
	opacity:.6;
}
.hero-title {
	max-width:750px;
	margin:0 auto;
	top:50%;
	position:relative;
	z-index:5;
}
.hero-decor {
	position:absolute;
	left:50%;
	margin-left:-50px;
	width:100px;
	height:50px;
}
.t-dec {
	top:-60px;
}
.b-dec {
	bottom:-70px;
}
.half-circle {
    width: 100px;
    height: 50px;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
    border: 5px solid rgba(255,255,255,0.51);
    border-top: 0 !important;
	position:relative;
    box-sizing: border-box;
}
.half-circle:before {
	content:'\f005';
	position:absolute;
	top:50%;
	left:50%;
	width:20px;
	height:20px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
	margin-top:-15px;
	margin-left:-10px;
	color:#fff;
	font-size:18px;
}
.hero-title h3 {
	font-size:5.5em;
	position:relative;
	width:100%;
	margin-bottom:10px;
	color:#fff;
	font-weight:bold;
	letter-spacing:3px;
	padding:10px 0;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.hero-title h4 {
	font-size: 14px;
	text-transform:uppercase;
	letter-spacing:2px;
	position:relative;
	color:#fff;
	max-width:350px;
	margin:0 auto;
}
.hero-title h4:before , .hero-title h4:after {
	content:'';
	position:absolute;
	top:50%;
	width:75px;
	height:1px;
	opacity:0.6;
}
.hero-title h4:before {
	left:-90px;
}
.hero-title h4:after {
	right:-90px;
}
.hero-title h3 a  , .hero-title h4 a{
	color:#fff;
}
.hero-title h4 a {
	float:right;
}
.hero-title h4 a:hover , .hero-title h4 a:hover i {
	color:#fff;
}
.hero-title h4 a {
	padding-right:0;
}
.slides-pagination {
	z-index:20;
}
.mob-bg {
	display:none;
}
/*------ Page title  ------------------------------------------------*/
.page-title {
	float:left;
	max-width:550px;
	padding:30px 0 10px;
	position:relative;
	border-bottom:6px solid #eee;
}
.page-title h2 {
	float:left;
	max-width:550px;
	font-family:'futurastd-bold';
	text-transform:uppercase;
	padding-bottom:20px;
	font-size:44px;
	text-align:left;
	letter-spacing:2px;
	font-weight:bold;
}
.page-title h3 {
	float:left;
	width:100%;
	line-height:26px;
	font-size:12px;
	text-align:left;
	font-family:'futurastd-bold';
	text-transform:uppercase;
	margin-bottom:10px;
	color:#666;
}
.page-title h3 span {
	float:left;
	max-width:550px;
}
.fullwidth-slider-holder , .single-slider-holder {
	float:left;
	width:100%;
	position:relative;
}
.fullwidth-slider-holder .item {
	position:relative;
}
.full-width-holder {
	float:left;
	width:100%;
	position:relative;
}
.single-slider-holder .customNavigation a {
	position:absolute;
	top:50%;
	width:50px;
	height:50px;
	margin-top:-20px;
	border-radius:100%;
	color:#fff;
	font-size:13px;
	line-height:50px;
	z-index:10;
	background:rgba(51,51,51,.7);
	cursor:pointer;
}
.single-slider-holder .customNavigation a.next-slide {
	right:14px;
}
.single-slider-holder .customNavigation a.prev-slide {
	left:14px;
}
article {
	float:left;
	margin-top:10px;
	width:100%;
	position:relative;
}
.separator {
	width:56px;
	height:24px;
	margin:10px auto 10px;
	/*background:url(../images/separator.png) no-repeat center;*/
 }

.separator.hamburger{
	margin-block:60px 30px;
} 

.color-separator {
	background:url(../images/color-separator.png) no-repeat center;
}
.work-time {
	margin:0 auto;
	max-width:800px;
	position:relative;
}
.work-time:before {
	content:'';
	position:absolute;
	top:40%;
	left:50%;
	margin-left:-5px;
	width:10px;
	height:62%;
	background:url(../images/ver-separator.png) repeat-y;
}
.hours {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 44px;
	letter-spacing: 2px;
	padding-bottom: 20px;
	font-family: 'Source Sans Pro', sans-serif;
}
.big-number {
	margin:50px auto 0;
	position:relative;
}
.big-number a {
	font-size:40px;
	font-weight:bold;
	letter-spacing:1px;
	padding:10px 0;
	font-family: "Barlow", sans-serif;
    transition: all 500ms linear;
}
.big-number a:hover {
	color:#fff;
	text-decoration:underline;
}
/*------ Team  ------------------------------------------------*/
.team-box {
	float:left;
	width:100%;
	position:relative;
	margin-top:60px;
}
.team-links {
	max-width:800px;
	margin:50px auto;
	position:relative;
}
.team-item {
	float:left;
	width:25%;
	padding:20px;
}
.team-item  a {
	position:relative;
	float:left;
	width:100%;
	height:100%;
}
.team-item  a img {
	border-radius:100%;
}
.chefname {
	font-size:20px;
	font-weight:bold;
	letter-spacing:1px;
	padding:10px 0;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	display:inline-table;
}
 .chefinfo {
	display:inline-table;
	font-size:14px;
	font-weight:bold;
}
.white-popup-block {
    max-width: 900px;
    margin: 0 auto;
}
.popup-modal-dismiss {
    float: right;
}
.team-modal {
	float:left;
	width:100%;
	text-align:center;
    position: relative;
    padding: 50px;
    margin-top: 35px;
    margin-bottom: 35px;
	background: #fff;
}
.team-modal:before  {
	content:'';
	position:absolute;
	top:3.5%;
	left:3.5%;
	width:92%;
	height:92%;
	opacity:0.5;
}
.team-modal p {
	text-align:center;
}
.team-modal h5    {
	padding:10px 0;
	display:inline-table;
	font-weight:bold;
	font-size:16px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
}
.team-social  li {
	display:inline-block;
	padding:10px;
	font-size:23px;
}
.popup-modal-dismiss {
	position:absolute;
	top:10px;
	right:20px;
	height:50px;
	width:50px;
	background:#fff;
	z-index:2;
	line-height:50px;
	font-size:20px;
}
.team-details {
	position:absolute;
	top:30%;
	left:0;
	width:100%;
	font-weight:bold;
	font-size:18px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
	opacity:0;
}
.team-item  a img  , .team-details , .menu-item a {
    transition: all 300ms linear;
}
.team-item  a:hover img {
	transform: scale(0.9);
	opacity:0.3;
}
.team-item  a:hover .team-details {
	opacity:1;
}
/*------ menu ------------------------------------------------*/
.menu-holder{margin-block-start:30px;}
.menu-holder  , .menu-item{
	float:left;
	width:100%;
	position:relative;
}
.menu-item {
	padding:24px 20px;
}
.menu-item-details {
	display:table
}
.menu-item-desc {
	padding-right: 5px; font-family: 'Droid Serif', serif;
	font-weight:bold;
	font-style:italic;
	text-align:left;
	font-size:20px;
	display: table-cell;
	white-space: nowrap;
	width: 10%;
	line-height:15px;
}
.menu-item-dot {
	display: table-cell;
	width: 88%;
	border-bottom: 1px dotted #000;
}
.menu-item-price {
	text-align:left;
	font-size:24px;
}
.menu-item-prices {
	display: table-cell;
	white-space: nowrap;
	width: 2%;
	padding:10px 0 0 10px;
	text-align:left;
}
.hot-deal:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.hot-desc {
	position:absolute;
	top:0;
	left:0;
	padding:0 20px;
	color:#fff;
	height:30px;
	line-height:30px;
	font-size:14px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
}
.menu-bg {
	position:absolute;
	bottom:0;
	width:650px;
	height:400px;
	background-size: contain;
	background-repeat:no-repeat;
	background-position:bottom;
	opacity:0.2;
}
.lbd {
	left:0;
}
.rbd {
	right:0;
}
/*------ Gallery  ------------------------------------------------*/
.gallery-filters {
	float:left;
	width:100%;
}
.gallery-filters  a {
	margin-right:20px;
	line-height: 1;
	color: #666;
	position:relative;
	font-size:17px;
	letter-spacing:2px;
	font-weight:bold;
}
.gallery-items   {
	float:left;
	width:100%;
	margin-block-start: 80px;
}
.grid-item-holder , .box-item , .box-item a {
	 float:left;
	 width:100%;
	 height:auto;
	 position:relative;
	 overflow:hidden;
}
.box-item {
	height:100%
}
.hid-port-info .grid-item-holder {
	overflow:hidden;
}
.grid-small-pad  .grid-item-holder{
	padding:10px 20px 10px 0;
}
.gallery-item, .grid-sizer {
	width: 25%;
	position:relative;
}
.gallery-item-second,
.grid-sizer-second {
	width: 50%;
}
.gallery-item-three,
.grid-sizer-three {
	width: 75%;
}
.gallery-item img, .grid-sizer img {
	width: 100%;
	height: auto;
	position:relative;
	z-index:1;
}
.four-coulms .gallery-item , .four-coulms .grid-sizer{
	width:25%;
}
.three-coulms .gallery-item , .three-coulms .grid-sizer{
	width:33.33333%;
}
.three-coulms .gallery-item-second {
	width:66.66666%;
}
.box-item .overlay {
	z-index:2;
	opacity:0;
	background:#000;
}
.box-item i {
	position:absolute;
	top:-100%;
	left:50%;
	width:30px;
	height:30px;
	z-index:5;
	opacity:0;
	margin-top:-15px;
	font-size:28px;
	line-height:30px;
	margin-left:-15px;
}
.box-item:hover .overlay {
	opacity:0.6;
}
.box-item:hover i {
	top:50%;
	opacity:1;
}
.box-item i , .box-item .overlay {
    transition: all 400ms linear;
}
/*------ Shop ------------------------------------------------*/
.products {
	display: flex;
	flex-wrap:wrap;
	width:100%;
	position:relative;
	& .product-ingredients{
		flex:1;
		font-size:17px;
		p {
			text-align:center;
		}
	}
}
.products li.product-cat-mains {
	display:flex;
	flex-direction:column;
	width:33.33333%;
	padding:20px;
	margin-bottom:20px;
	overflow:hidden;
}
.product-cat-mains h4 {
	/*float:left;*/
	width:100%;
	padding:20px 0;
	font-family: 'Neuropolitical Rg';
	font-weight: bold;
	font-size: 20px;
	line-height: 15px;
    transition: all 400ms linear;
}
.product-cat-mains h4:hover {
	opacity:0.6;
}
.product-cats {
	padding:10px 0 20px 0;
}
.product-cats li{
	display:inline-block;
	padding:4px 10px;
}
.product-cats li a {
	font-weight:bold;
	font-size:14px;
    transition: all 400ms linear;
}
.product-price {
	float:left;
	width:100%;
	padding:20px 0;
	border-top:1px solid #ccc;
	position:relative;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.product-price span {
	position:relative;
	display:inline-table;
	font-size:18px;
	top:0;
    transition: all 200ms linear;
}
.product-price a {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	font-size:24px;
	line-height:64px;
	bottom:-100%;
	opacity:0;
    transition: all 400ms linear;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.product-price a:hover , .product-cats li a:hover{
	color:#000;
}
.products li:hover .product-price span   {
	opacity:0;
}
.products li:hover .product-price a  {
	opacity:1;
	bottom:0;
}
.product-price:before , .product-price:after{
	content:'';
	position:absolute;
	top:0;
	left:50%;
}
.product-price:before {
	background:#fff;
	width:20px;
	margin-left:-10px;
	margin-top:-6px;
	height:10px;
	z-index:1;
}
.product-price:after {
	width:6px;
	height:6px;
	border-radius:100%;
	margin-left:-3px;
	margin-top:-4px;
	z-index:2;
	background:#000;
}
.orderby {
	margin-bottom:26px;
}
.orderby-wrap {
	max-width: 250px;
	margin: 0 auto;
}
.content-pagination {
	float:left;
	width:100%;
	margin-top: 25px;
    font-size: 1.1em
}
.content-pagination a {
    color: #999;
    display:inline-block;
	font-weight:300;
    height: 60px;
	margin: 0 4px;
    line-height: 60px;
    width: 60px;
	background: #191919;
	border-radius: 4px;
}
.content-pagination a.current-page, .content-pagination a:hover {
    color:#fff;
}
.content-pagination a i {
	font-size: .8em;
}
.content-pagination  span.content-pagination-num{
	display: inline-block;
    padding:9px 15px;
	color:#666;
	font-size:12px !important;
	text-transform:uppercase;
}
.prevposts-link{
	margin-right:8px;
}
.nextposts-link{
	margin-left:8px;
}
.product-item-holder {
	float:left;
	width:100%;
	position:relative;
	border-top:1px solid #eee;
	padding:50px 0;
}
.product-slider  .owl-controls{
	margin-top:20px;
}
.product-slider  .item-link {
    position: relative;
	float:left;
	width:33.33333333%;
	height:110px;
	margin-bottom:10px;
    transition: all 200ms linear;
}
.owl-theme .owl-controls .active .item-link {
	  opacity:0.6;
}
.product-image  , .product-item , .pr-opt{
	float:left;
	position:relative;
	margin-bottom:20px;
	width:100%;
}
.product-image .customNavigation a {
	position:absolute;
	top:50%;
	width:50px;
	height:50px;
	margin-top:-55px;
	background: #222;
	color:#fff;
	border-radius:100%;
	font-size:14px;
	line-height:50px;
	z-index:10;
	cursor:pointer;
}
.product-image .customNavigation a.next-slide {
	right:16px;
}
.product-image .customNavigation a.prev-slide {
	left:16px;
}
.product-item  {
	text-align:left;
}
.product-item  h2 {
	font-family: 'Neuropolitical Rg';
	padding-bottom:20px;
	border-bottom:1px solid #eee;
	font-size:44px;
}
.pr-opt {
	border-bottom:1px solid #eee;
}
.product-rating  , .product-item-price{
	float:left;
	width:50%;
	padding:20px;
	font-size:14px;
 
}
.product-rating   {
 
	position: relative;
	top: 6px;
}
.product-rating ul , .product-rating span  {
	float:right;
}
.product-rating span {
	margin-left:4px;
}
.product-rating li {
	display:inline-block;
	padding:1px;
}
.product-item-price {
 	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	font-size:24px
}
.product-item-price span {
	text-decoration:line-through;
	padding-right:10px;
	font-size:15px;
	opacity:0.6;
}
.product-item p {
	max-width:600px;
}
.quantity {
	float:left;
	padding:20px 0;
	width:100%;
}
.quantity input {
	border:none;
	-webkit-appearance:none;
	float:left;
	width:60px;
	height:60px;
	background:#262526;
	font-size:14px;
	color:#fff;
	cursor:pointer;
	line-height:50px;
	text-align:center;
}
.quantity a {
	float:left;
	height:60px;
	padding:0 30px;
	line-height:60px;
	font-size:16px;
	letter-spacing:2px;
	font-weight:bold;
	border-radius:4px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	margin-left:25px;
	background:#262526
}
.product-meta {
	float:left;
	max-width:750px;
	text-align:left;
	margin:30px 0;
}
.sku_wrapper , .posted_in , .tagged_as {
	float:left;
	width:100%;
	margin-bottom:10px;
	font-size:16px;
	font-weight:bold;
}
.product-meta span a {
	padding-left:6px;
}
 
/* --------Checkout table------------------------------ */
.checkout-table {
	border: 1px solid #eee;
	float:left;
	width:100%;
	font-size:1.4em;
}
.checkout-table > tbody > tr > td,
.checkout-table > tbody > tr > th,
.checkout-table > tfoot > tr > td,
.checkout-table > tfoot > tr > th,
.checkout-table > thead > tr > td,
.checkout-table > thead > tr > th {
	border-top: 1px solid #eee;
	padding: 20px;
}
.checkout-table tr td,
.checkout-table tr th {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;
	border-left: 1px solid #eee;
}

.checkout-table tbody tr td:first-child,
.checkout-table tbody tr th:first-child {
	max-width: 36px;
}
.pr-remove {
	vertical-align: middle !important;
	text-align: center;
}
.order-money {
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	font-size:18px;
}
.order-count {
	border:none;
	width:60px;
	height:50px;
	text-align:center;
	font-weight:bold;
}
.product-name {
	font-weight:bold;
}
.coupon-holder {
	float:left;
	width:100%;
	margin-top:30px;
	padding: 12px 0 20px 0;
	border-bottom:1px solid #eee;
}
 
.coupon-holder input{
	float:left;
	outline: none;
    height: 60px;
    padding-left: 15px;
    color: #a4a4a3;
    text-decoration: none;
    border: 1px solid #eee;
    border-radius: 4px;
    font-family: 'Source Sans Pro', sans-serif;
    background: #f9f9f9;
 
}
.coupon-holder button.btn-a{
	float:left;
	margin-left:20px;
	border: none;
    outline: none;
    padding: 20px 40px;
    line-height: 18px;
    cursor: pointer;
    font-size: 1.1em;
    background: #262526;
    border-radius: 2px;
    -webkit-appearance: none;
    font-family: 'Droid Serif', serif;
    font-weight: 700;
	transition: all 0.3s ease-in-out
}
.coupon-holder button.btn-uc {
	border: none;
    outline: none;
    padding: 20px 40px;
    line-height: 18px;
    cursor: pointer;
    font-size: 1.1em;
    background: #262526;
    font-family: 'Droid Serif', serif;
    border-radius: 2px;
    -webkit-appearance: none;
    font-weight: 700;
	transition: all 0.3s ease-in-out;
}
.cart-totals {
	float:left;
	width:100%;
	margin-top:30px;
}
.cart-totals h4 {
	font-size:20px;
	font-weight:bold;
	letter-spacing:1px;
	padding:20px 0;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
.cart-totals  button {
	float:right;
	margin-top:30px;
	border: none;
    outline: none;
    padding: 20px 40px;
    line-height: 18px;
    cursor: pointer;
    font-size: 1.1em;
    background: #262526;
    border-radius: 2px;
    -webkit-appearance: none;
    font-family: 'Droid Serif', serif;
    font-weight: 700;
	transition: all 0.3s ease-in-out;
}
.cart-totals  button:hover {
	color:#fff;
}
/*------ Footer  ------------------------------------------------*/
footer {
	float:left;
	width:100%;
	position:relative;
	z-index:5;
	background:#191919;
}
footer:before {
	content:'';
	position:absolute;
	top:-30px;
	left:50%;
	margin-left:-40px;
	width:80px;
	height:80px;
	border-radius:100%;
	background:#191919;
}
footer:after {
	content:'';
	position:absolute;
	top:0;
	left:50%;
	margin-left:-5px;
	width:10px;
	height:10px;
	border-radius:100%;
}
.footer-inner {
	float:left;
	width:100%;
	padding:90px 0;
}
.to-top-holder {
	float:left;
	width:100%;
 	background:#262526;
	position:relative;
	padding:40px 0 30px;
}
.tby{
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	transition: all 400ms linear;
	&:hover{
		color: #C59D5F;
	}
}
.to-top  {
	float:right;
	cursor:pointer;
	overflow:hidden;
	position:relative;
}
.to-top span {
	position:relative;
	display:inline-table;
	font-size:16px;
	top:0;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
    transition: all 200ms linear;
}
.to-top i {
	position:absolute;
	left:0;
	width:100%;
	height:100%;
	font-size:24px;
	line-height:24px;
	top:-100%;
	opacity:0;
    transition: all 400ms linear;
}
.to-top:hover span  {
	opacity:0;
}
.to-top:hover i  {
	opacity:1;
	top:0;
}
.footer-info {
	float:left;
	width:100%;
	padding-bottom:20px;
}
.footer-info h4 {
	/*float:left;*/
	width:100%;
	color:#fff;
	font-size: 28px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	margin-bottom:40px;
	font-family: 'Neuropolitical Rg';
}
.footer-info p {
	color:#fff;
	font-size:14px
}
.to-top-holder p {
	float:left;
	color:#fff;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:1px;
}
p.footer-credits{
	font-family: "Barlow", sans-serif;
	font-size:17px;
	a{
		color:#fff;
		text-decoration: underline;
	}
}
.footer-social{
	/*float:left;*/
	width:100%;
	position:relative;
	margin-top: 80px;
}
.footer-social:before {
	content: '';
	position: absolute;
	left: 50%;
	bottom: -10px;
	width: 40px;
	margin-left: -20px;
	height: 1px;
	background: rgba(255,255,255,.4);
}
.footer-social h3 {
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	font-size:34px;
	margin-bottom: 50px;
}
.footer-social ul {
	margin-top:20px;
}
.footer-social ul li {
	display:inline-block;
}
.footer-social ul li a {
	float:left;
	width:40px;
	height:fit-content;/*40px;*/
	color:#fff;
	font-size:25px;
}
footer .bold-separator span {
	background:#fff !important;
}
.footer-contacts {
	/*float:left;*/
	width:100%;
	color:#919191;
}
.footer-contacts li {
	font-size:16px;
	/*display:inline-block;*/
	padding:0 20px;
	position:relative;
}
/*.footer-contacts li:before {
	content:'';
	position:absolute;
	top:8px;
	left:0;
	width:5px;
	height:5px;
	border-radius:100%;
	background:#919191;
}*/
.footer-contacts li a {
	color:#919191;
	font-size:16px;
	font-weight: 500;
}
.footer-contacts li:first-child:before {
	display:none;
}
.footer-contacts li a , .footer-social ul li a , .team-social  li a, .popup-modal-dismiss{
    transition: all 400ms linear;
}
.footer-info p {
	text-align: center;
	font-size: 1.5em;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
    color: #919191;
}
/* ------Blog------------------------------------------------------------ */
article , .pagination {
	float:left;
	width:100%;
	position:relative;
}
.single-slider .box-links {
	text-align:center !important;
}
article p {
	float:left;
	width:100%;
	padding-bottom:20px;
}
.display-posts p {
	line-height:25px;
}
article h4 {
	font-size: 24px;
	line-height: 36px;
	font-style: normal;
	font-weight: bold;
	font-weight: 700;
	color: #222222;
	padding-bottom:10px !important;
}
.mas-posts h4 {
	padding-bottom:0 !important;
}
.mas-posts .ms-grid-item {
	margin:0;
	padding:0;
}
.mas-posts article {
	padding:10px;
    transition: all 300ms linear;
}
.mas-posts article:hover {
	background:#f4f4f4;
}
article h4 a:hover {
	color:#666;
}
.mas-posts article h4 {
	font-size:18px;
}
.post-media {
	float:left;
	width:100%;
	position:relative;
	margin:20px 0;
	text-align:center !important;
}
.post-meta {
	margin:14px 0 14px 0;
}
.display-posts article {
	border-bottom:1px solid #eee;
	padding:0 0 30px 0;
	margin-bottom:30px;
}
.post-meta li {
	display:inline-block;
	margin-right:8px;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	font-size:15px;
	color:#666;
}
.post-meta li a {
	font-size:15px;
}
.mas-posts article ul.post-meta li {
	margin-bottom:6px;
}
.post-link {
	font-size:18px;
    transition: all 300ms linear;
	font-weight:bold;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
	margin-bottom:20px;
}
.post-tags {
	float:left;
	width:100%;
	margin-top:40px;
}
.post-tags li , .tagcloud li  {
	display:inline-block;
	margin-right:4px;
}
.post-tags li a , .tagcloud li a {
	float:left;
	font-size: .9em;
	font-weight: 500;
	text-transform: uppercase;
	padding: 15px 20px;
	background: #f5f5f5;
	margin-bottom: 3px;
	text-decoration:none;
	color:#444;
    transition: all 300ms linear;
}
.post-tags li a:hover , .tagcloud li a:hover {
	color:#fff;
}
.pagination {
	margin-top:20px;
	text-align:center;
	vertical-align: middle;
	border:1px solid rgba(0,0,0,.07);
	border-radius: 4px;
}
.pagination ul   {
	float:left;
	margin:20px 0 30px;
	border:1px solid rgba(0,0,0,.07);
	border-radius: 4px;
}
.pagination  a {
	float:left;
	padding: 12px 18px;
	position:relative;
	font-size:13px;
	color:#666;
	letter-spacing:1px;
	border-right:1px solid rgba(0,0,0,.07);
}
.pagination a.current-page, .pagination a:hover {
    color:#fff;
}
.widget {
	float:left;
	width:100%;
	margin-bottom:50px;
}
.widget .customNavigation a {
	text-align:center !important;
}
.searh-holder {
	max-width:450px;
	margin-top:20px;
	position:relative;
}
.searh-inner {
	float:left;
	width:100%;
	position:relative;
}
.search {
	border:1px solid rgba(0,0,0,.07);
	float: left;
	border-radius:4px;
	position:relative;
	outline: none;
	padding: 0px 0px 0px 30px;
	width: 100%;
	height: 60px;
	color: #000;
	background: #f9f9f9;
	-webkit-appearance: none;
	font-family: 'Source Sans Pro', sans-serif;
}
.search-submit {
	border: none;
	outline: none;
	cursor: pointer;
	background:none;
	position:absolute;
	top:0;
	font-size: 1.4em;
	right:30px;
	height: 60px;
}
.search-submit:hover i {
	opacity:0.5;
}
.tabs-menu {
 
    float: left;
    clear: both;
}
.tabs-menu li {
 
    float: left;
    margin-right: 10px;
 
}
.tabs-menu li.current a {
    position: relative;
    background: #fff;
    z-index: 5;
}
.tabs-menu li a {
	float: left;
	background: #f4f4f4;
    padding: 0 40px;
	height: 50px;
	line-height: 50px;
    color: #666;
    transition: all 300ms linear;
	font-size: 1.1em;
    border:1px solid rgba(0,0,0,.07);
	border-bottom:none;
	border-radius: 4px 4px 0 0;
}
.tabs-menu .current a {
    color: #fff;
}
.tab {
    /*border: 1px solid rgba(0,0,0,.07);*/
    float: left;
    margin-bottom: 20px;
    width: 100%;
}
.tab-content {
    width: 100%;
    padding: 40px 40px 40px 0;
    display: none;
}
#tab-1 {
	display: block;
}
.widget-posts li , .widget-comments li {
	padding: 12px 0;
}
.widget-posts-img , .widget-comments-img {
	float:left;
	width:30%;
}
.widget-comments-img  img{
	 border-radius:100%;
}
.widget-posts-descr, .widget-comments-descr{
	float:left;
	width:70%;
	padding-left:10px;
}
.widget-comments-descr a {
	font-size:12px;
	letter-spacing:1px;
}
.widget-posts-date  , .widget-comments-date{
	float:left;
	width:100%;
	margin-top:10px;
	color:#666;
}
.widget h3 {
	font-size:26px;
	font-weight:bold;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
	margin-bottom:40px;
}
.cat-item{
	padding-bottom: 8px;
	margin-bottom: 15px;
}
.cat-item a{
	font-weight: 400;
	font-size: 15px;
    transition: all 200ms linear;
}
#comments{
	text-align:left;
	padding-top: 10px;
}
#comments-title , #reply-title{
	border-bottom: 1px solid #eee;
	padding-bottom: 30px;
	margin-bottom: 5px;
	font-size:16px;
	text-transform:uppercase;
	font-weight:bold;
	color:#666;
}
.comment{
	float: left;
}
.comment-body{
	position: relative;
	margin-left: 70px;
	padding-top: 30px;
}
.comment-author{
	position: absolute;
	top: 30px;
	left: -66px;
}
.comment-author img{
	border-radius: 100%;
}
.comment .children{
	margin-left: 70px;
}
.fn{
	display: block;
	margin-bottom: 10px;
}
.comment-meta, .comment-meta a{
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	font-size:12px;
	letter-spacing:1px;
	color:#494949;
	padding-bottom:10px;
}
#respond{
	margin-top:40px;
}
#reply-title{
	padding-bottom:30px;
	margin-bottom:5px;
}
.comment-notes{
	margin-top: 10px; color: #a4a4a3;
}
 
.control-group{
	float: left;
	width: 100%;
	padding-right: 100px;
	 position: relative;
}
.control-group label{
	margin-left: 10px;
	font-weight: 200;
	position: absolute;
	right: 40px;
	top: 20px;
	height: 60px;
	line-height: 60px;
}
 
.control-group .controls input {
	margin-top: 15px;
	outline: none;
	height:60px;
	padding-left: 15px;
	color: #a4a4a3;
	text-decoration: none;
	border: 1px solid #eee;
	border-radius: 4px;
    font-family: 'Source Sans Pro', sans-serif;
	background: #f9f9f9;
	width: 100%;
}
.control-group .controls textarea {
	margin-top: 15px;
	outline: none;
	height: 180px;
	background: #f9f9f9;
	padding-left: 15px;
	padding-top: 15px;
	color: #a4a4a3;
	resize: vertical;
	text-decoration: none;
	border: 1px solid #eee;
	border-radius: 4px;
	width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}
.controls button {
	border: none;
	outline: none;
	margin-top:20px;
	padding:20px 40px;
	line-height:18px;
	cursor:pointer;
	font-size:1.1em;
	background: #262526;
	border-radius:2px;
	-webkit-appearance: none;
    font-family: 'Droid Serif', serif;
    font-weight: 700;
}
.controls button:hover{
	color:#fff;
}
/*------Subscribe ------------------------------------------*/
.subcribe-form{
	margin:0 auto;
	position:relative;
	max-width:300px;
}
.subcribe-form form {
	float:left;
	width:100%;
	position:relative;
	margin-top:10px;
}
#subscribe input.enteremail{
	float:left;
	width:100%;
	height:60px;
	border:none;
	background:rgba(255,255,255,.1);
	padding:0 50px 0 25px;
	outline:none;
	-webkit-appearance: none;
	color:#fff !important;
	border-radius: 2px;
	font-family: 'Source Sans Pro', sans-serif;
	position: relative;
	z-index: 2;
}
.subcribe-form form:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: 1;
    border: 1px dotted ;
	border-radius: 2px;
	opacity: .4
}
#subscribe input.enteremail::-webkit-input-placeholder {
	color: #fff;
	font-family: 'Source Sans Pro', sans-serif;
}
#subscribe  .subscribe-button{
	position: absolute;
	top: 0;
	right: 0;
	height:60px;
	background:none;
	border:none;
	width:60px;
	color:#fff;
	cursor:pointer;
	border-radius:0;
	z-index: 2;
	font-size: 1.3em;
	-webkit-appearance: none;
	transition: all 0.3s ease-in-out;
}
#subscribe  .subscribe-button:hover {
	color:#000;
}
.subscribe-message {
	color:#fff;
	position: absolute;
	bottom: -40px;
	left: 0;
	text-align: left;
	right: 0;
}
.subscribe-message i {
	font-size:18px;
	padding-right:10px;
}
.subscribe-message.error i {
	color:#e52f40
}
/*------ Map -------------------------------------------------*/
.map-box {
	float:left;
	width:100% ;
	height:400px;
	position:relative;
	overflow:hidden;
}
.map-holder {
	position:absolute;
	width:100%;
	height:550px;
	top:-50px;
	left:0;
	z-index:10;
}
#map-canvas {
	float:left;
	width:100%;
	height:550px;
	position:relative;
}
/*------ Promotion  ------------------------------------------------*/
.promotion-item {
	max-width:1024px;
	margin:20px auto 20px;
}
.promotion-title  {
	float:left;
	width:100%;
}
.promotion-title h4 {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 24px;
	letter-spacing: 2px;
	text-align:left;
	padding-bottom: 12px;
	float:left;
}
.promotion-title span {
	float:left;
	margin:12px 0 0 10px;
	font-family: 'Droid Serif', serif;
	font-size: 12px;
}
.promotion-details {
	margin-top:20px;
	display:table;
}
.promotion-desc{
	display: table-cell;
	white-space: nowrap;
	width: 10%;
	line-height:15px;
	padding-right: 5px;
	font-family: 'Droid Serif', serif;
	font-weight:bold;
	font-style:italic;
	text-align:left;
	font-size:20px;
}
.promotion-dot {
	display: table-cell;
	width: 88%;
	border-bottom: 1px dotted #fff;
}
.promotion-price {
	text-decoration: line-through;
	text-align:left;
}
.promotion-discount {
	font-weight: 700;
	font-size: 30px;
	text-decoration: none;
	padding-left: 3px;
}
.promotion-prices {
	display: table-cell;
	white-space: nowrap;
	width: 2%;
	padding-left:10px;
	text-align:left;
}
/*------ Testimonials  ------------------------------------------------*/
.testimonials-holder {
	max-width:800px;
	margin:0 auto;
	position:relative;
}
.testimonials-holder p {
	text-align:center;
}
.testimonials-holder h4 a {
	padding:20px 0;
	display:inline-table;
	font-weight:bold;
	font-size:16px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	letter-spacing:2px;
}
.testimonials-slider ul {
	float:left;
	width:100%;
	margin:20px 0 20px 0;
}
.testimonials-slider ul li {
	display:inline-block;
	font-size:16px;
}
.testimonials-holder .customNavigation {
	position:relative;
	margin-top:-30px;
}
.testimonials-holder .customNavigation a {
	color:#fff;
	font-size:18px;
	padding:20px 10px;
	display:inline-block;
	cursor:pointer;
	transition: all 0.3s ease-in-out;
}
/*------ Reservation  ------------------------------------------------*/
.reservation-form-holder {
	float:left;
	width:100%;
}
#reservation-form h3 {
	float:left;
	width:100%;
	margin-bottom:30px;
	text-align:left;
	font-size:20px;
	text-transform:uppercase;
	font-weight:bold;
	letter-spacing:1px;
	font-family: 'Source Sans Pro', sans-serif;
}
/*------ Contact  ------------------------------------------------*/
.contact-details {
	float:left;
	width:100%;
	padding-top:30px;
	text-align:left;
}
.contact-details h3 {
	font-size:24px;
	text-transform:uppercase;
	letter-spacing:1px;
	font-weight:bold;
	margin-bottom:20px;
}
.contact-details h4 {
	margin-bottom:10px;
	font-size:14px;
	text-transform:uppercase;
	letter-spacing:1px;
	font-weight:bold;
}
.contact-details ul  {
	padding-bottom:20px;
}
.contact-details ul li a {
	padding:2px 0;
	float:left;
	font-size:13px;
	width:100%;
}
.contact-details ul li a:hover {
	text-decoration:underline;
	color:#000;
}
.contact-form-holder {
	max-width:600px;
	margin:0 auto;
}
#contact-form  , #comment-form{
	width:100%;
	/*float:left;*/
	margin-top:30px;
}
#contact-form input[type="text"] , #contact-form textarea , #comment-form input[type="text"]  , #comment-form textarea , #reservation-form input  , #reservation-form  select , #reservation-form textarea{
	/*float:left;*/
	width: 100%;
	margin-bottom:20px;
	font-size: 13px;
	height:60px;
	position:relative;
	z-index:20;
	padding-left:25px;
	color:#000;
	border:0;
	border:1px solid #eee;
	border-radius:2px;
	background: #f9f9f9;
	font-weight:400;
	font-family: 'Source Sans Pro', sans-serif;
}
#contact-form input[type="text"]::-webkit-input-placeholder , #contact-form textarea::-webkit-input-placeholder , #comment-form input[type="text"]::-webkit-input-placeholder , #contact-form textarea ::-webkit-input-placeholder , #reservation-form input::-webkit-input-placeholder  , #reservation-form  select::-webkit-input-placeholder , #reservation-form textarea::-webkit-input-placeholder  {
	font-family: 'Source Sans Pro', sans-serif;
 
}
#contact-form textarea  , #comment-form textarea {
	height:170px;
	resize:none;
	padding-top: 20px;
	overflow: auto;
	box-shadow: none;
}
#submit , #submit-res	{
	border:none;
	padding:15px 40px;
	background:#000;
	line-height:17px;
	cursor:pointer;
	font-size:18px;
	letter-spacing:2px;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
	-webkit-appearance: none;
	border-radius:4px;
    transition: all 200ms linear;
	text-transform: uppercase;
}
#submit:hover , #submit-res:hover{
	color:#fff;
}
.reservation-form-holder  #submit-res {
	margin-top:50px;
}
.error_message , #success_page h1 {
	font-size:18px;
	letter-spacing:2px;
	padding-bottom:10px;
}
#success_page h3{
	text-align:left;
	font-size:18px;
}
#success_page p {
	margin-bottom:10px;
	padding:6px 6px 6px 0;
	color:#000;
	font-size:18px;
	letter-spacing:2px;
	text-align:center;
	/*font-family: 'Playball', cursive;*/
	font-family: 'Neuropolitical Rg';
}
#message fieldset , #message2  {
	border:none;
}
#contact-form{
	.is-invalid{
		border-color:rgba(var(--bs-danger-rgb)) !important;
	}
	.invalid-feedback{
		text-align: left;
		font-size: 1.2em;
	}
}

/*------ Video ------------------------------------------------*/
.media-container {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
}
.video-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	line-height: 0;
	z-index: 1;
}
.video-container video {
	width: 100%;
}
.video-iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden !important;
    top: 0;
    left: 0;
    padding: 0 !important;
}
.video-iframe iframe  {
    position: absolute;
    top: -75px;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
}
.media-container.video-parallax {
	height:150%;
	top:-25%;
}
.resp-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
	margin-bottom:20px;
}
.resp-video iframe,
.resp-video object,
.resp-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.video-mask {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
}
.video-holder iframe {
    position: absolute;
    top: -75px;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
	z-index:1;
}
/*------ css-animation - ------------------------------------------------*/
.transition{
    transition: all 500ms linear;
}
.transition2{
    transition: all 200ms linear;
}
.fl-l {
	float:left;
}
/*------  Responsive ------------------------------------------------------*/
@media only screen and  (max-width: 1536px) {
.menu-bg {
	width:50%;
	height:80%;
}
}
@media only screen and  (max-width: 1036px) {
.mob-bg {
	display:block;
}
.header-inner .container {
    width: 100%;
}
.header-inner {
	padding: 0 30px;
}
.nav-button-holder  {
	display:block;
}
.nav-holder {
	position:absolute;
	top:52px;
	margin-top: 0;
	left:0;
	width:100%;
	background:#191919;
	padding:30px 0;
	display:none;
	height:330px;
	overflow:none;
}
nav ul li {
	float:left;
	width:100%;
	margin-bottom:10px;
	text-align:left;
	left:0;
	opacity:1;
	right: 0;
	padding-left: 30px;
}
nav li a.act-link:before , nav li a.act-link:after {
	left:7px;
}
.hero-title-holder {
	width:100%;
}
.hero-title {
	top:45%;
	float:none;
	margin: 0 auto;
	border-right:none;
	padding:50px;
}
.hero-title h3 {
	font-size:22px;
}
.hero-title h4 {
	font-size:12px
}
.page-title {
	padding-bottom:20px;
}
.page-title h2 {
	font-size:30px;
}
.gallery-item {
	width:50% !important;
}
header.sticky nav a ,  header.flat-header nav a{
	color:#fff;
}
nav a:before , nav a:after , .work-time:before , .hero-title h4:before , .hero-title h4:after {
	display:none;
}
.nav-social {
	margin-right:20px;
}
.subnav {
	margin-top:24px;
}
.map-holder {
	top:0;
}
.bg-parallax  , .media-container.video-parallax{
	height:100%;
	top:0;
}
.team-modal img {
	margin:30px 0
}
nav li ul {
	margin: 0;
	padding-left:0;
	opacity: 1;
	visibility: hidden;
	position: relative;
	width:100%;
	display:none;
	top:0;
	left: 0;
	z-index: 1;    	
	border:none;
}
nav li ul li  {
	padding:3px 0;
}
nav li ul li:first-child {
	margin-top:10px;
}
nav li:hover > ul {
	display: block;
} 
}
@media only screen and  (max-width: 756px) {
.team-item  , .three-coulms .gallery-item , .three-coulms .grid-sizer , .three-coulms .gallery-item-second , .products li.product-cat-mains{
	width:50% !important;
}
.slides-pagination {
	display:none;
}
.slides-navigation {
   bottom:30px;
   right:10px;
}
}
@media only screen and  (max-width: 540px) {
.team-item  , .chefinfo , .team-item  , .three-coulms .gallery-item , .three-coulms .grid-sizer , .three-coulms .gallery-item-second , .products li.product-cat-mains{
	width:100% !important;
}
.grid-small-pad  .grid-item-holder{
	padding:10px;
}
#wrapper {
	padding:0;
}
.parallax-section h2  , .section-title h3 , .big-number a{
	font-size:28px;
}
.to-top-holder p  , .to-top{
	width:100%;
	text-align:center;
}
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	.bg {
		background-attachment: scroll !important;
		-webkit-background-size:cover;
		background-position:center;
	}
}

#event-detail + section {padding-block-start: 120px}
#event-detail .product-item h2{border:none;font-size:37px;}

.close-day{
	margin-top:20px;
	text-align:center;
	color:#fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 34px;
	letter-spacing: 2px;
	padding-bottom: 20px;
	font-family: "Source Sans Pro", sans-serif;
}

.section-address{
	& h4{
		font-family: 'Neuropolitical Rg';
		font-size:37px;
	}
	& ul {
		font-size:23px;
		margin-block-start:20px;
	}
}

.restaurant-logo{
	height: 150px;
  	margin-bottom: 40px;
}

/* Remove link */
.WidgetBackground__Content-sc-1ho7q3r-2.ciCnpO a[href]:not([class]) {
	visibility: hidden!important;
	width:0 !important;
}

em{font-style: italic;}
strong{font-weight:bold;}

/* cursore */
.owl-carousel .owl-item{cursor: auto;}
.link-overlay{
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	z-index: 6;
}
/* Recensioni google */
.wemMr,.gmtLmX{
	background-color: rgba(255, 255, 255, 0.4) !important;
}

.restaurant-dishes-section{
	width: 100%;
	position: relative;
	padding: 30px 0;
	background: #fff;

	.section-title .separator{
  		height: auto;
  		margin: 10px auto 0px;
	}
}

#gallery-hotel {
	.gallery-items{
		margin-top:40px;
	}
	.book-now{
		margin-top:70px;
	}
}

#booking-review.testimonials-holder{
	margin-block:100px 70px;
	max-width:1100px;
	.customNavigation{
		margin-top: 0 !important;
		& a{
			color:#000;
		}
	} 
	.testimonials-slider{
		margin-block-start:40px;
	}
	.owl-wrapper{
		display: flex!important;
	}
	.item{ 
		padding-inline:20px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height:100%;
	}
	p{flex:1;}
	h4{
		width:100%;
		padding: 20px 0;
		display: inline-table;
		font-weight: bold;
		font-size: 16px;
		font-family: 'Playball', cursive;
		font-family: "Neuropolitical Rg";
		letter-spacing: 2px;
	}
}

.contact-form-holder.menu-page{
	margin-bottom:120px;
}

.wh-button {
  bottom: 10px;
  position: fixed;
  right: -135px;
  z-index: 3;
  background: #25d366;
  border: none;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: var(--fs-21);
  padding-block: 10px;
  padding-inline: 25px;
  text-decoration: none;
  svg{
	margin-right:10px;
	width:35px;
	height:35px;
  }
}
.alert {z-index:3;}

.wrapper-booking{
	position:relative;
	margin: 0 auto;
  	padding-inline: 46px;
	margin-block-start:20px;
	display:flex;
	justify-content: center;
	width:fit-content;
	@media only screen and  (max-width: 600px) {
		flex-wrap:wrap;
	}
	.booking{
		--bui_color_brand_primary_background:#003b95;
		background: var(--bui_color_brand_primary_background);
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		vertical-align: baseline;
		border-radius: calc(32px / 5.5) calc(32px / 5.5) calc(32px / 5.5) 0;
		height: 32px;
		width: 32px;
		min-width: 32px;
		padding-block-end: 0;
		line-height: 1;
		margin-inline:20px 10px;
	}
	.line{margin-inline:20px;}
	a{
		text-decoration:underline;
		@media only screen and  (max-width: 600px) {
			margin-top:10px;
			width:100%;
		}
	}
}
.wrapper-booking::after{right:0;}

.contact-submit{
  border: none;
  padding: 15px 40px;
  background: #000;
  line-height: 17px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: 'Playball', cursive;
  font-family: "Neuropolitical Rg";
  border-radius: 4px;
  transition: all 200ms linear;
  text-transform: uppercase;
  color: #C59D5F;
  &:hover{
	background: #C59D5F;
  }
}

.product-meta{
	float:none;
	.posted_in{
		float:none;
	}
}

.restaurant-form-title{
	margin-top:100px
}

.video-container{
	height:100% !important;
}
.video-parallax + .overlay{opacity:.7;}

div:has(.grecaptcha-badge){display:none;}

/* checkbox privacy */
#contactform-privacy,
#nsl-privacy {
    appearance: none;
}
.form-group.prv{margin-bottom:50px;}
.form-group.prv label {
    position: relative;
    font-size: 16px;
    padding-inline-start: 30px !important;
    line-height: 1.5;
    text-align: left;
    color: #333 !important;
    @media(max-width:768px){
        font-size: clamp(18px,3.9062vw,20px);
    }
    & a {
        color: #333;
        text-decoration: underline;
    }
}

.form-hp .form-group.prv label,
.form-hp .form-group.prv label a {
	color:#fff !important;
}

.form-group.prv label::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 9px;
    display: none;
    height: 7px;
    width: 11px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
}

.form-group.prv label::before {
    width: 18px;
    height: 18px;
    border-color: #231f20;
    border-radius: 4px;
    cursor: pointer;
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 0;
    border: 1px solid rgba(0, 0, 0, .15);
    background-color: #fff;
    transition: border .15s ease-in-out, color .15s ease-in-out;
}

.form-group.prv input[type="checkbox"]:checked + label::after {
    display: block;
}
.form-group.prv input.is-invalid + label::before { border:1px solid red; }

.recaptcha-terms{ 
	text-align: left;
	margin-top:10px;
	a { text-decoration: underline;}	
}
.form-hp .recaptcha-terms a {color:#fff;}
